import { APIClient } from '../lib/apiHelper';

const { get, post, put } = new APIClient();

export default {
	getDeviceList: (data) => post('/device/list', data),
	deviceSetting: (data) => post('/device/setting', data),
	grouping: (data) => post('/device/grouping', data),
	exituser: (data) => post('/device/exituser', data),
	getInstallList: (data) => post('/device/install', data),
	getInstallSave: (data) => post('/device/install/save', data),
	getInstallModify: (data) => post('/device/install/modify', data),
	firmwareList: (data) => post('/firmware/list', data),
	firmwareUpdate: (data) => post('/firmware/update', data),
	firmwareRemove: (data) => post('/firmware/remove', data),
	installRemove: (data) => post('/install/remove', data),
	commandList: (data) => post('/device/command', data),
	getDeviceDashboard: (data) => post('/device/dashboard', data),
	getArea: (data) => post('/dashboard/area', data),
	getTopdata: (data) => post('/dashboard/topdata', data),
	getChart: (data) => post('/dashboard/chart', data),
	getDeviceStatus: (data) => post('/dashboard/device/status', data),
	getAutocomplete: (data) => post('/dashboard/autocomplete', data),
	getDeviceChart: (data) => post('/dashboard/device/chart', data),
	getInfo: (data) => post('/dashboard/info', data),
	updateDevices: (data) => post('/device/update', data),
	getAlarmList: (data) => post('/device/alarm', data),
	getDashboardDeviceList: (data) => post('/dashboard/device/list', data),
	getDeviceErrorList: (data) => post('/device/error/list', data),
};
