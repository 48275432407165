import React, { lazy } from 'react';
import Login from '../pages/CommonMenu/Login/login';
import SystemSetting from '../pages/rnslab/SystemSetting';
import MicChartPage from '../pages/rnslab/charts/micChart';
import XyzChartPage from '../pages/rnslab/charts/xyzChart';
import XyzChart2Page from '../pages/rnslab/charts/xyzChart2';
import FFTChartPage from '../pages/rnslab/charts/fftChart';
import ArDashboardPage from '../pages/rnslab/dashboard/ArDashboard';
import ArcollectedData from '../pages/rnslab/arcollectedData/ArCollectedDataList';
import ArUserDataList from '../pages/rnslab/arcollectedData/ArUserDataList';
import ArUsage from '../pages/rnslab/arcollectedData/ArUsage';
import MainPage from '../pages/rnslab/srcollectedData/Main';
import MobilePage from '../pages/rnslab/srcollectedData/Mobile';
import ArSystemSetting from '../pages/rnslab/arSystemSetting';
import NrDashboardPage from '../pages/rnslab/dashboard/NrDashboard';
import NrCollectedDataList from '../pages/rnslab/nrcollectedData/NrCollectedDataList';
import NrSystemSetting from '../pages/rnslab/nrSystemSetting';
import NrDeviceList from '../pages/rnslab/nrcollectedData/NrDeviceList';
import Profile from '../pages/rnslab/Profile';
import SignUp from '../pages/rnslab/joinus';
import WrMainPage from '../pages/rnslab/wrcollectedData/Main';
const DashboardPage = lazy(() => import('../pages/rnslab/dashboard'));
const CollectedData = lazy(() => import('../pages/rnslab/collectedData'));
const UserList = lazy(() => import('../pages/rnslab/userList'));
const GroupList = lazy(() => import('../pages/rnslab/groupList'));
const WorkerList = lazy(() => import('../pages/rnslab/workerList'));
const DeviceList = lazy(() => import('../pages/rnslab/deviceList'));
const DeviceSettings = lazy(() => import('../pages/rnslab/deviceSettings'));
const Firmware = lazy(() => import('../pages/rnslab/firmware'));
const DeviceCommand = lazy(() => import('../pages/rnslab/deviceCommand'));

const contents = {
	common: {
		profile: {
			id: 'profile',
			text: '프로필',
			type: 'user',
			icon: 'Profile',
			path: '/profile',
			element: <Profile />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	},
	withOutAsidePage: {
		joinus: {
			id: 'joinus',
			text: '설치',
			type: 'user',
			icon: 'PersonAdd',
			path: '/install',
			element: <SignUp />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
		login: {
			id: 'login',
			text: '로그인',
			type: 'withOutAsidePage',
			icon: 'Login',
			path: '/login',
			element: <Login />,
			exact: true,
			subMenu: null,
		},
		fft: {
			id: 'test',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Test',
			path: '/fft',
			element: <FFTChartPage />,
			exact: true,
			subMenu: null,
		},
		mic: {
			id: 'mic',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Mic',
			path: '/mic',
			element: <MicChartPage />,
			exact: true,
			subMenu: null,
		},
		xyz: {
			id: 'xyz',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Xyz',
			path: '/xyz',
			element: <XyzChartPage />,
			exact: true,
			subMenu: null,
		},
		xyz2: {
			id: 'xyz2',
			text: '테스트',
			type: 'withOutAsidePage',
			icon: 'Xyz2',
			path: '/xyz2',
			element: <XyzChart2Page />,
			exact: true,
			subMenu: null,
		},
		srdashboard: {
			id: 'srdashboard',
			text: '대시보드',
			type: 'withOutAsidePage',
			icon: 'BarChart',
			path: '/srdashboard',
			element: <MainPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		wrdashboard: {
			id: 'wrdashboard',
			text: '대시보드',
			type: 'withOutAsidePage',
			icon: 'BarChart',
			path: '/wrdashboard',
			element: <WrMainPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		srmobile: {
			id: 'srmobile',
			text: '모바일',
			type: 'withOutAsidePage',
			icon: 'BarChart',
			path: '/srmobile',
			element: <MobilePage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
	},
	SUPERADMIN: {
		dashboard: {
			id: 'dashboard',
			text: '대시보드',
			type: 'superadmin',
			icon: 'BarChart',
			path: '/dashboard',
			element: <DashboardPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		collectedData: {
			id: 'collectedData',
			text: '수집 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/collectedData',
			element: <CollectedData />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		userDataList: {
			id: 'userDataList',
			text: '사용자 관리',
			type: 'group',
			icon: 'ManageAccounts',
			path: '/userDataList',
			element: <UserList />,
			exact: true,
			permissions: ['GROUP'],
			subMenu: null,
		},
		groupDataList: {
			id: 'groupDataList',
			text: '그룹 관리',
			type: 'superadmin',
			icon: 'Group',
			path: '/groupDataList',
			element: <GroupList />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
		wokerDataList: {
			id: 'wokerDataList',
			text: '작업자 관리',
			type: 'user',
			icon: 'Engineering',
			path: '/wokerDataLists',
			element: <WorkerList />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
		// deviceDataList: {
		// 	id: 'deviceDataList',
		// 	text: '기기 목록 조회',
		// 	type: 'user',
		// 	icon: 'SpeakerPhone',
		// 	path: '/deviceDataList',
		// 	element: <DeviceList />,
		// 	exact: true,
		// 	subMenu: null,
		// },
		deviceSettings: {
			id: 'deviceSettings',
			text: '기기 관리',
			type: 'user',
			icon: 'AppSettingsAlt',
			path: '/deviceSettings',
			element: <DeviceSettings />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		deviceInstall: {
			id: 'deviceInstall',
			text: '기기 설치 정보',
			type: 'user',
			icon: 'PermDeviceInformation',
			path: '/deviceInstall',
			element: <DeviceList />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
		deviceCommand: {
			id: 'deviceCommand',
			text: '기기 명령 정보',
			type: 'user',
			icon: 'ConnectedTv',
			path: '/deviceCommand',
			element: <DeviceCommand />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
		firmware: {
			id: 'firmware',
			text: '펌웨어 관리',
			type: 'user',
			icon: 'Save',
			path: '/firmware',
			element: <Firmware />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
		systemSetting: {
			id: 'systemSetting',
			text: '시스템 설정',
			type: 'user',
			icon: 'Settings',
			path: '/systemSetting',
			element: <SystemSetting />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	},
	NR: {
		nrdashboard: {
			id: 'nrdashboard',
			text: '대시보드',
			type: 'superadmin',
			icon: 'BarChart',
			path: '/nrdashboard',
			element: <NrDashboardPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrdeviceSettings: {
			id: 'nrdeviceSettings',
			text: '기기 목록',
			type: 'user',
			icon: 'AppSettingsAlt',
			path: '/nrdeviceSettings',
			element: <NrDeviceList />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrcollectedData: {
			id: 'nrcollectedData',
			text: '수집 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/nrcollectedData',
			element: <NrCollectedDataList />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		nrsystemSetting: {
			id: 'nrsystemSetting',
			text: '시스템 설정',
			type: 'user',
			icon: 'Settings',
			path: '/nrsystemSetting',
			element: <NrSystemSetting />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	},
	SR: {
		srdashboard: {
			id: 'srdashboard',
			text: '대시보드',
			type: 'withOutAsidePage',
			icon: 'BarChart',
			path: '/srdashboard',
			element: <MainPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		collectedData: {
			id: 'collectedData',
			text: '수집 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/collectedData',
			element: <CollectedData />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		deviceSettings: {
			id: 'deviceSettings',
			text: '기기 관리',
			type: 'user',
			icon: 'AppSettingsAlt',
			path: '/deviceSettings',
			element: <DeviceSettings />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		systemSetting: {
			id: 'systemSetting',
			text: '시스템 설정',
			type: 'user',
			icon: 'Settings',
			path: '/systemSetting',
			element: <SystemSetting />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	},
	WR: {
		wrdashboard: {
			id: 'wrdashboard',
			text: '대시보드',
			type: 'withOutAsidePage',
			icon: 'BarChart',
			path: '/wrdashboard',
			element: <WrMainPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
	},
	AR: {
		ardashboard: {
			id: 'ardashboard',
			text: '대시보드',
			type: 'superadmin',
			icon: 'BarChart',
			path: '/ardashboard',
			element: <ArDashboardPage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		arcollectedData: {
			id: 'arcollectedData',
			text: '수집 데이터',
			type: 'user',
			icon: 'Preview',
			path: '/arcollectedData',
			element: <ArcollectedData />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		arUserDataList: {
			id: 'arUserDataList',
			text: '수용가별 모니터링',
			type: 'user',
			icon: 'Monitor',
			path: '/arUserDataList',
			element: <ArUserDataList />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		usage: {
			id: 'usage',
			text: '누적 사용량 조회',
			type: 'user',
			icon: 'Preview',
			path: '/usage',
			element: <ArUsage />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		deviceSettings: {
			id: 'deviceSettings',
			text: '기기 관리',
			type: 'user',
			icon: 'AppSettingsAlt',
			path: '/deviceSettings',
			element: <DeviceSettings />,
			exact: true,
			permissions: ['USER', 'GROUP'],
			subMenu: null,
		},
		arsystemSetting: {
			id: 'arsystemSetting',
			text: '시스템 설정',
			type: 'user',
			icon: 'Settings',
			path: '/arsystemSetting',
			element: <ArSystemSetting />,
			exact: true,
			permissions: [],
			subMenu: null,
		},
	}
}

export default contents;
