import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { observer } from 'mobx-react';
import ReactApexChart from 'react-apexcharts';
import XyzChart2Page from './xyzChart2';

const dbState = {
    10: '호흡',
    20: '속삭임',
    30: '부드럽게 이야기',
    40: '조용한 도서관',
    50: '조용한 사무실',
    60: '대화',
    70: '레스토랑',
    80: '바쁜 교통',
    90: '오토바이',
    100: '전기 드릴',
    110: '록 콘서트',
    120: '우뢰'
}

const MicChartPage = observer(({guageHidden = false}) => {
    const [mic, setMic] = useState([39])
    const [micLabels, setMicLabels] = useState([])
    const [sensingTime, setSensingTime] = useState(0)
    const [minMic, setMinMic] = useState(0)
    const [maxMic, setMaxMic] = useState(0)
    const [avgMic, setAvgMic] = useState(0)
    const [wsocket, setWsocket] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [reconnect, setReconnect] = useState(0)
    const [screenWidth, setScreenWidth] = useState(0)

    const IMEI = new URL(window.location.href).searchParams.get('IMEI');

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let timer

        setInterval(() => {
            if (!wsocket) {
                setReconnect(r => r + 1)
            } else if (!isPlaying) {
                wsocket.send(JSON.stringify({ IMEI }));
            }
        }, 5000)

        return (
            clearInterval(timer)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let temp = []
        for (let i = 0; i < 60; i++) {
            if (i % 10 === 0)
                temp.push((i - 60) + ' s')
            else if (i === 59) temp.push('0 s')
            else temp.push('')
        }
        temp.push('0 s')
        setMicLabels(temp)
        // 컴포넌트가 마운트될 때 웹소켓 연결을 수행합니다.
        const ws = new WebSocket('wss://sensor.rnslab.com/socket/v1/dashboard/mic');

        // 웹소켓 이벤트 리스너를 설정합니다.
        ws.onopen = () => {
            setWsocket(ws)
            console.log('웹소켓이 연결되었습니다.');
            ws.send(JSON.stringify({ IMEI }));
        };

        ws.onmessage = (event) => {
            setIsPlaying(true)
            // 서버로부터 메시지를 수신했을 때 실행됩니다.
            let data = JSON.parse(event.data)
            if (data?.mic) {
                setMic(data.mic)
                if (data?.sensingTime) setSensingTime(data.sensingTime)
                if (data?.maxMic) setMaxMic(data.maxMic)
                if (data?.minMic) setMinMic(data.minMic)
                if (data?.avgMic) setAvgMic(data.avgMic)
                ws.send(JSON.stringify({ IMEI }));
            }
            setIsPlaying(false)
        };

        ws.onclose = () => {
            console.log('웹소켓이 닫혔습니다.');
        };

        // 컴포넌트가 언마운트될 때 웹소켓 연결을 종료합니다.
        return () => {
            ws.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconnect]); 


    let options = {
        series: [{
            name: '',
            data: mic
        }],
        chart: {
            animations: {
                enabled: false
            },
            type: 'area',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20,
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 0,
            dashArray: 0,
        },
        fill: {
            type: 'gradient',
            colors: '#FFA500',
            gradient: {
                shade: 'light',
                type: "vertical",
                opacityFrom: 0.6,
                opacityTo: 1,
                colorStops: []
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: 'dB',
                offsetX: 27,
                offsetY: -98,
                rotate: 0,
                style: {
                    fontWeight: '#000',
                }
            },
            min: 20,
            max: 120,
            tickAmount: 5,
        },
        xaxis: {
            // tickAmount: 7,
            categories: micLabels, 
            labels: {
                formatter: function (val, i) {
                    return val;
                },
                rotate: 0,
                rotateAlways: false,
            },
            title: {
                text: '',
                rotate: 0,
            },
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val + ' dB'
                }
            }
        },
        annotations: {
            xaxis: micLabels.map(x =>
                x !== '' ? {
                    x: x, // x 축의 값을 기준으로 세로선을 그립니다.
                    borderColor: '#cecece',
                    strokeDashArray: 0,
                    opacity: 0.5,
                } : '').filter(x => x !== ''),
        }
    };

    var options2 = {
        series: [mic[mic.length - 1]],
        chart: {
            height: 350,
            type: 'radialBar',
            offsetY: -10
        },
        dataLabels: {
            name: {
                show: true,
            },
            value: {
                show: true,
            }
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 135,
                dataLabels: {
                    name: {
                        fontSize: '18px',
                        color: undefined,
                        offsetY: 80
                    },
                    value: {
                        offsetY: 0,
                        fontSize: '30px',
                        color: undefined,
                        formatter: function (val) {
                            return val + " dB";
                        }
                    }
                },
                dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 4,
                    opacity: 0.24
                }
            }
        },
        fill: {

            type: 'gradient',
            gradient: {
                // gradientToColors: [ '#6E73FF', '#78F5CA', '#F5E478', '#FF746E', '#FF413B'],
                shade: 'dark',
                shadeIntensity: 0.15,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [40, 60, 80, 100, 120]
            },
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        stroke: {
            dashArray: 4
        },
        labels: [dbState[parseInt(mic[mic.length - 1] / 10) * 10]],
        circularAxis: {
            labels: {
                show: true,
                style: {
                    fontSize: '12px',
                },
                formatter: (value, index) => {
                    // 각도에 따른 눈금 레이블 설정
                    return value + '°';
                },
            },
        }
    };

    return (
        <>
            <Page className='scroll-zone' style={{ overflowX: 'hidden' }}>
                <PageWrapper>
                    {/* <div>{sensingTime}</div> */}
                    <div style={{ width: screenWidth, marginBottom: 50, flex: 1, marginTop: -20, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <ReactApexChart
                            options={options2}
                            series={options2.series}
                            height={250}
                            width={250}
                            type={'radialBar'}
                        />
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <h2>소음</h2>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div>최소: {minMic}</div>
                            <div style={{ marginLeft: 20 }}>평균: {avgMic}</div>
                            <div style={{ marginLeft: 20 }}>최대: {maxMic}</div>
                            <div style={{ marginLeft: 20 }}>(단위: dB)</div>
                        </div>
                        <ReactApexChart
                            style={{ marginLeft: 10 }}
                            options={options}
                            series={options.series}
                            width={screenWidth - 10}
                            type={'area'}
                            height={250}
                        />
                        {!guageHidden &&
                        <XyzChart2Page  />}
                    </div>
                </PageWrapper>
            </Page>
        </>
    );
});

export default MicChartPage;
