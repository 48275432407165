import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ReactApexChart from 'react-apexcharts';
import Card, { CardBody } from '../../../../components/bootstrap/Card';

const XyzChartPage = observer(({IMEI}) => {
    const [sensingTime, setSensingTime] = useState('')
    const [xyz, setXyz] = useState([])
    const [xyzLabels, setXyzLabels] = useState([])
    const [minXyz, setMinXyz] = useState(0)
    const [maxXyz, setMaxXyz] = useState(0)
    const [avgXyz, setAvgXyz] = useState(0)
    const [wsocket, setWsocket] = useState(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [reconnect, setReconnect] = useState(0)
    const [screenWidth, setScreenWidth] = useState(0)

    useEffect(() => {
        setScreenWidth(window.innerWidth);
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let timer

        setInterval(() => {
            if (!wsocket) {
                setReconnect(r => r + 1)
            } else if (!isPlaying && IMEI) {
                wsocket.send(JSON.stringify({ IMEI }));
            }
        }, 5000)

        return (
            clearInterval(timer)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        let temp = []
        for (let i = 0; i < 60; i++) {
            if (i % 10 === 0) 
                temp.push((i - 60) + ' s')
            else if (i === 59) temp.push('0 s')
            else temp.push('')
        }
        setXyzLabels(temp)
        // 컴포넌트가 마운트될 때 웹소켓 연결을 수행합니다.
        const ws = new WebSocket('wss://sensor.rnslab.com/socket/v1/dashboard/xyz');

        // 웹소켓 이벤트 리스너를 설정합니다.
        ws.onopen = () => {
            setIsPlaying(false)
            setWsocket(ws)
            console.log('웹소켓이 연결되었습니다.');
            if (IMEI) ws.send(JSON.stringify({ IMEI }));
        };

        ws.onmessage = (event) => {
            setIsPlaying(true)
            // 서버로부터 메시지를 수신했을 때 실행됩니다.
            let data = JSON.parse(event.data)

            if (data?.xyz) {
                setXyz(data.xyz)
                if (data?.xyzMax) setMaxXyz(data.xyzMax)
                if (data?.xyzMin) setMinXyz(data.xyzMin)
                if (data?.xyzAvg) setAvgXyz(data.xyzAvg)

                ws.send(JSON.stringify({ IMEI }));
            }
            setIsPlaying(false)
        };

        ws.onclose = () => {
            console.log('웹소켓이 닫혔습니다.');
        };

        // 컴포넌트가 언마운트될 때 웹소켓 연결을 종료합니다.
        return () => {
            ws.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reconnect]); 


    let options = {
        series: xyz,
        labels: xyzLabels,
        chart: {
            animations: {
                enabled: false
            },
            type: 'area',
            height: 500,
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            offsetX: -20,
        },
        colors: [
            '#ff6666', // red
            '#6666ff', // blue
            '#008000', // green
        ],
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        title: {
            text: '',
            align: 'center'
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 1,
            dashArray: 0,
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: 'm/s²',
                offsetX: 27,
                offsetY: -159,
                rotate: 0,
                style: {
                    fontWeight: '#000',
                }
            },
            tickAmount: 5,
        },
        xaxis: {
            // tickAmount: 7,
            labels: {
                formatter: function (val, i) {
                    return val;
                },
                rotate: 0,
                rotateAlways: false,
            },
            title: {
                text: '',
                rotate: 0,
            },
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val) {
                    return val + ' '
                }
            }
        },
        annotations: {
            xaxis: xyzLabels.map(x =>
                x !== '' ? {
                    x: x, // x 축의 값을 기준으로 세로선을 그립니다.
                    borderColor: '#cecece',
                    strokeDashArray: 0,
                    opacity: 0.5,
                } : '').filter(x => x !== ''),
        }
        
    };

    return (
        <div className='row'>
            <div className='col-3'>
                <div className='d-flex flex-row justify-content-around'>
                    <Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #5d87ff' }}>
                        <CardBody className={`rounded-2 align-items-center text-center`} >
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ fontSize: '1.7rem', color: '#ff6666' }}><b>X : {xyz[0]?.data[xyz[0]?.data?.length - 1] || 0}</b></div>
                                <div style={{ fontSize: '1.7rem', marginLeft: 40, color: '#6666ff' }}><b>Y : {xyz[1]?.data[xyz[1]?.data?.length - 1] || 0}</b></div>
                                <div style={{ fontSize: '1.7rem', marginLeft: 40, color: '#008000' }}><b>Z : {xyz[2]?.data[xyz[2]?.data?.length - 1] || 0}</b></div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>{minXyz}</div>
                </div> */}
                <div className='d-flex flex-row justify-content-around' style={{marginTop: 100}}>
                    <Card stretch className={`custom-box-shadow rounded-2`} style={{ border: '1px solid #5d87ff' }}>
                        <CardBody className={`rounded-2 align-items-center text-center`} >
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div>최소: {minXyz}</div>
                                <div style={{ marginLeft: 20 }}>평균: {avgXyz}</div>
                                <div style={{ marginLeft: 20 }}>최대: {maxXyz}</div>
                                <div style={{ marginLeft: 20 }}>(단위: m/s²)</div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className='col-9'>
                <div className='d-flex flex-row w-100'>
                    <Card stretch className={`custom-box-shadow rounded-2 w-100`} style={{ border: '1px solid #fa896b' }}>
                        <CardBody className={`rounded-2 align-items-center text-center`} >
                            <ReactApexChart
                                options={options}
                                series={options.series}
                                width={'100%'}
                                type={'line'}
                                height={200}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
});

export default XyzChartPage;
